var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"friend-set-up-container",class:{
    forbidden: !_vm.isFriend
  },style:({
    height: `${_vm.$screenHeight}px`
  })},[_c('div',{staticClass:"set-menu-container"},[_c('row-info-bar',{attrs:{"title":"设置备注","val":_vm.friend && _vm.friend.comment,"icon":""},nativeOn:{"click":function($event){return _vm.commentEdit.apply(null, arguments)}}}),(false)?_c('row-info-bar',{attrs:{"title":"设置权限","icon":""},nativeOn:{"click":function($event){return _vm.scopeEdit.apply(null, arguments)}}}):_vm._e()],1),_c('van-button',{staticClass:"relieve-relations-bt",attrs:{"loading":_vm.requesting},on:{"click":_vm.onFriendRemove}},[_vm._v(" 解除关系 ")]),_c('router-link',{staticClass:"back-bt",attrs:{"tag":"div","to":{
      path: `/friend/${_vm.friendUid}`
    },"replace":""}},[_vm._v("返回")]),_c('van-popup',{attrs:{"position":"top","close-on-click-overlay":false},model:{value:(_vm.showCommentEdit),callback:function ($$v) {_vm.showCommentEdit=$$v},expression:"showCommentEdit"}},[_c('van-form',[_c('van-cell-group',{staticClass:"textarea"},[_c('van-field',{attrs:{"autosize":"","type":"textarea","placeholder":"输入备注名...","maxlength":10},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1),_c('van-cell-group',[_c('van-button',{staticClass:"comment-bts",on:{"click":_vm.onCancel}},[_vm._v("取消")]),_c('van-button',{staticClass:"comment-bts submit",attrs:{"loading":_vm.requesting},on:{"click":_vm.onSubmitComment}},[_vm._v("保存")])],1)],1)],1),_c('topic-scope')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }