<template>
  <div></div>
</template>

<script>
export default {
  name: 'component-friend-topic-scope',
};
</script>

<style></style>
