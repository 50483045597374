<template>
  <div
    class="friend-set-up-container"
    :class="{
      forbidden: !isFriend
    }"
    :style="{
      height: `${$screenHeight}px`
    }"
  >
    <div class="set-menu-container">
      <row-info-bar
        title="设置备注"
        @click.native="commentEdit"
        :val="friend && friend.comment"
        icon
      ></row-info-bar>
      <row-info-bar v-if="false" title="设置权限" icon @click.native="scopeEdit"></row-info-bar>
    </div>
    <van-button class="relieve-relations-bt" :loading="requesting" @click="onFriendRemove">
      解除关系
    </van-button>
    <router-link
      class="back-bt"
      tag="div"
      :to="{
        path: `/friend/${friendUid}`
      }"
      replace
      >返回</router-link
    >
    <!-- 备注修改 -->
    <van-popup v-model="showCommentEdit" position="top" :close-on-click-overlay="false">
      <van-form>
        <van-cell-group class="textarea">
          <van-field
            v-model="comment"
            autosize
            type="textarea"
            placeholder="输入备注名..."
            :maxlength="10"
          ></van-field>
        </van-cell-group>
        <van-cell-group>
          <van-button class="comment-bts" @click="onCancel">取消</van-button>
          <van-button class="comment-bts submit" :loading="requesting" @click="onSubmitComment"
            >保存</van-button
          >
        </van-cell-group>
      </van-form>
    </van-popup>
    <!-- 话题权限设置 -->
    <topic-scope></topic-scope>
  </div>
</template>

<script>
import forbiddenMixin from '@/mixins/forbidden';
import { mapMutations, mapState } from 'vuex';
import rowInfoBar from '../common/_row-info-bar.vue';
import topicScope from './_scope.vue';

export default {
  name: 'page-friend-set-up',
  props: ['id'],
  mixins: [forbiddenMixin],
  components: {
    rowInfoBar,
    topicScope,
  },
  data() {
    return {
      requesting: false,
      showCommentEdit: false,
      comment: '',
      isFriend: true,
      showTopicScope: false,
    };
  },
  computed: {
    ...mapState(['user', 'friends']),
    friend() {
      if (this.friends && this.friends.length > 0) {
        const info = this.friends.find((item) => Number(item.info.uid) === Number(this.friendUid));
        return info;
      }
      return null;
    },
    friendUid() {
      return this.id;
    },
    scopeUrl() {
      return `/topic/scope/setup/${this.friendUid}`;
    },
  },
  watch: {
    friends: {
      handler(val) {
        if (val && val.length === 0) {
          this.notFriend('你们还未成为好友');
        }
      },
      immediate: true,
    },
    friend: {
      handler(val) {
        if (val === undefined) {
          this.notFriend('你们还未成为好友');
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    ...mapMutations(['UPDATE_FRIEND']),
    notFriend(msg) {
      const that = this;
      that.isFriend = false;
      that
        .$dialog({
          message: msg,
        })
        .then(() => {
          that.$router.back(-1);
        });
    },
    onFriendRemove() {
      const that = this;
      if (!that.requesting) {
        that.requesting = true;
        that.$dialog
          .confirm({
            title: '提醒',
            message: '解除好友之后所有关联消失，\n是否确认要解除关系？',
          })
          .then(() => {
            that.$request.get(`api/user/friend/remove/${that.friendUid}`).then((res) => {
              if (res.data === 'SUCCESS') {
                that.$router.replace({
                  path: `/friend/${that.friendUid}`,
                });
              }
              that.requesting = false;
            });
          })
          .catch(() => {
            that.requesting = false;
          });
      }
    },
    commentEdit() {
      const that = this;
      that.showCommentEdit = true;
    },
    onSubmitComment() {
      const that = this;
      if (!that.requesting) {
        that.requesting = true;
        that.$request
          .post(`api/user/friend/comment/${that.friendUid}`, {
            comment: that.comment,
          })
          .then((res) => {
            if (res.data === 'success') {
              that.UPDATE_FRIEND({
                uid: that.friendUid,
                comment: that.comment,
              });
            }
            that.onCancel();
          })
          .finally(() => {
            that.requesting = false;
          });
      }
      // if (that.comment) {
      // } else {
      //   that.$notify({
      //     message: '请输入备注名',
      //     type: 'danger',
      //   });
      // }
    },
    onCancel() {
      const that = this;
      that.requesting = false;
      that.showCommentEdit = false;
    },
    scopeEdit() {
      console.log('111');
    },
  },
};
</script>

<style lang="scss">
.textarea {
  height: 260px;
  .van-cell__value {
    padding: 10px 16px 0 !important;
    margin: 0 !important;
  }
  .van-field__control {
    font-size: 16px;
    &::placeholder {
      font-size: 16px;
    }
    color: #888;
  }
}
.comment-bts {
  height: 99px !important;
  border: 0;
  font-size: 32px;
  color: #888 !important;
  &.submit {
    color: #000 !important;
  }
}
</style>
